import { SVGProps } from "react";

const GooglePlaySvg = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="24"
      height="24"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <path d="M12 0c-6.626 0-12 5.372-12 12 0 6.627 5.374 12 12 12 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12zm-4.667 18.804l6.143-6.125 2.166 2.158-7.303 4.049c-.278.154-.618.152-.894-.006l-.112-.076zm5.524-6.743l-5.857 5.839v-11.679l5.857 5.84zm3.659-2.413l2.862 1.586c.281.156.455.449.455.766s-.174.61-.455.766l-2.94 1.63-2.342-2.335 2.42-2.413zm-9.248-4.395l.177-.133c.276-.158.616-.16.894-.006l7.381 4.092-2.244 2.237-6.208-6.19z" />
    </svg>
  );
};
export default GooglePlaySvg;
