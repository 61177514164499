import { ButtonProps } from "./Button.d";
import "./Button.scss";

const Button = ({
  category = "primary",
  children,
  disabled = false,
  icon,
  onClick,
  textAlign = "center",
  title,
  type = "button",
}: ButtonProps) => {
  const fillSvg = {
    primary: "white",
    secondary: "#E90D2F",
    transparent: "#6d7c90",
  };
  return (
    <button
      className={`btn ${category}`}
      type={type}
      disabled={disabled}
      onClick={() => onClick?.()}
      title={title}
    >
      <div style={{ textAlign }}>
        {icon && <div style={{ color: fillSvg[category] }}>{icon}</div>}
        {icon && children && <div className="space" />}
        <div className="label">{children}</div>
      </div>
    </button>
  );
};

export default Button;
