import ArrowBottom from "../../../../icons/Arrow/ArrowBottom";
import ArrowTop from "../../../../icons/Arrow/ArrowTop";
import LessonCardChallenge from "../LessonCardChallenge/LessonCardChallenge";
import { AccordeonItemType } from "./AccordeonItem.d";
import "./AccordeonItem.scss";
import { useState } from "react";

const AccordeonItem = ({ idx, name, lessons }: AccordeonItemType) => {
  const [viewContent, setViewContent] = useState(false);

  return (
    <div key={`div-${idx}`} className="accordeon-item">
      <div
        onClick={() => setViewContent(!viewContent)}
        className="accordeon-title"
        style={{
          backgroundColor: idx % 2 === 0 ? "#FBB6B9" : "#FFF2F2",
        }}
      >
        <h4>{name}</h4>
        {viewContent ? <ArrowBottom /> : <ArrowTop />}
      </div>
      {viewContent &&
        lessons.map((lesson: any, index: number) => (
          <LessonCardChallenge
            key={`lesson-card-${index}`}
            illustration={lesson?.preview ?? "/images/preview_default.png"}
            title={lesson.name}
            link={`/lessons/${lesson.id}`}
          />
        ))}
    </div>
  );
};

export default AccordeonItem;
