import CrossSVG from "../../icons/Cross";
import "./Modal.scss";
import cx from "classnames";

const Modal = ({
  size,
  title,
  content,
  onClose,
}: {
  size: "small" | "medium" | "large";
  title?: string;
  content: JSX.Element;
  onClose: () => void;
}) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((event.target as HTMLElement).classList.contains("modal-background")) {
      onClose();
    }
  };
  return (
    <div className="modal" onClick={handleClick}>
      <div className="modal-background" />
      <div
        className={cx("modal-content", {
          [size]: true,
        })}
      >
        <div className="cross" onClick={onClose}>
          <CrossSVG />
        </div>
        {title && <div className="title">{title}</div>}
        <div>{content}</div>
      </div>
    </div>
  );
};

export default Modal;
