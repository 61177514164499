const _getTokenName = (): string =>
  process.env.REACT_APP_TOKEN_PREFIX ?? "ssp-";

export const checkToken = (): boolean =>
  !!localStorage.getItem(`${_getTokenName()}access`);

export const getToken = (refresh = false): string | null =>
  localStorage.getItem(`${_getTokenName()}${refresh ? "refresh" : "access"}`);

export const resetToken = () => {
  localStorage.removeItem(`${_getTokenName()}access`);
  localStorage.removeItem(`${_getTokenName()}refresh`);
};

export const setToken = (access: string, refresh: string) => {
  localStorage.setItem(`${_getTokenName()}access`, access);
  localStorage.setItem(`${_getTokenName()}refresh`, refresh);
};
