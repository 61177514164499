const ReadmeSVG = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="40.5" height="29.454" viewBox="0 0 40.5 29.454" {...props}>
      <path
        id="Icon_awesome-readme"
        data-name="Icon awesome-readme"
        d="M37.146,3.27h-9.83a7.23,7.23,0,0,0-7.059,5.646A7.235,7.235,0,0,0,13.2,3.27H3.375A3.376,3.376,0,0,0,0,6.645V23.927A3.376,3.376,0,0,0,3.375,27.3H9.682c7.186,0,9.33,1.716,10.357,5.273a.224.224,0,0,0,.422,0c1.034-3.558,3.178-5.273,10.357-5.273h6.307A3.376,3.376,0,0,0,40.5,23.927V6.652A3.382,3.382,0,0,0,37.146,3.27ZM17.016,21.93a.245.245,0,0,1-.246.246H5.5a.245.245,0,0,1-.246-.246V20.32a.245.245,0,0,1,.246-.246H16.777a.245.245,0,0,1,.246.246v1.61Zm0-4.282a.245.245,0,0,1-.246.246H5.5a.245.245,0,0,1-.246-.246v-1.61a.245.245,0,0,1,.246-.246H16.777a.245.245,0,0,1,.246.246v1.61Zm0-4.282a.245.245,0,0,1-.246.246H5.5a.245.245,0,0,1-.246-.246v-1.61A.245.245,0,0,1,5.5,11.51H16.777a.245.245,0,0,1,.246.246v1.61Zm18.232,8.557A.245.245,0,0,1,35,22.17H23.73a.245.245,0,0,1-.246-.246v-1.61a.245.245,0,0,1,.246-.246H35.009a.245.245,0,0,1,.246.246v1.61Zm0-4.282a.245.245,0,0,1-.246.246H23.73a.245.245,0,0,1-.246-.246v-1.61a.245.245,0,0,1,.246-.246H35.009a.245.245,0,0,1,.246.246v1.61Zm0-4.282a.245.245,0,0,1-.246.246H23.73a.245.245,0,0,1-.246-.246v-1.6a.245.245,0,0,1,.246-.246H35.009a.245.245,0,0,1,.246.246v1.6Z"
        transform="translate(0 -3.27)"
        fill="#e90d2f"
      />
    </svg>
  );
};

export default ReadmeSVG;
