import Button from "../../components/Button/Button";
import HeadbandBellyPremium from "../../components/HeadbandBellyPremium/HeadbandBellyPremium";
import LessonCard from "../../components/LessonCard/LessonCard";
import TemporaryPromotion from "../../components/TemporaryPromotion/TemporaryPromotion";
// import Promotion from "../../components/Promotion/Promotion";
import useWindowSize from "../../hooks/useWindowSize";
import ApiService from "../../services/ApiService";
import { normalizeString } from "../../services/MiscService";
import { useGoBack } from "../../services/NavigationService";
import { findPrimaryColor } from "./Course.function";
import "./Course.scss";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Course = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [course, setCourse] = useState<any>([]);
  const [listFilter, setListFilter] = useState<
    {
      name: string;
      id: string | null;
    }[]
  >([]);
  const [activeFilter, setActiveFilter] = useState<string | null>("Tout");
  const [searchTerm, setSearchTerm] = useState("");
  const goBack = useGoBack();
  const { width } = useWindowSize();

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    ApiService.get(`e-learning/courses/${params.course_id}`)
      .then((resp) => {
        if (
          resp.data?.template === "challenge" ||
          (!resp.data?.template && resp.data.type === "challenge")
        ) {
          navigate(`/courses-challenge/${params.course_id}`, {
            replace: true,
          });
        }
        setListFilter([
          { id: null, name: "Tout" },
          ...resp.data.modules.filter(
            (module: any) =>
              !["médias mensuels", "articles", "lives"].includes(
                module.name.toLowerCase()
              )
          ),
        ]);
        setCourse(resp.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Une erreur s'est produite lors de la récupération des données du cours. Veuillez réessayer plus tard."
        );
      });
  }, [navigate, params]);

  const highlightText = (text: string, highlight: string) => {
    const normalizedText = normalizeString(text);
    const normalizedHighlight = normalizeString(highlight);
    const parts = normalizedText.split(
      new RegExp(`(${normalizedHighlight})`, "gi")
    );
    return (
      <span>
        {parts.map((part, index) =>
          part === normalizedHighlight ? <mark key={index}>{part}</mark> : part
        )}
      </span>
    );
  };

  return (
    <div id="course">
      <HeadbandBellyPremium />
      <TemporaryPromotion />
      {/* <Promotion /> */}
      <div
        className="course-description"
        style={{
          backgroundImage:
            `url(${
              width >= 500
                ? course.illustration
                : course.mobile_illustration ?? course.illustration
            })` ??
            `linear-gradient(to bottom right, ${findPrimaryColor(
              course
            )} 10%, white 160%)`,
        }}
      >
        <Button category="secondary" onClick={() => goBack()}>
          &lt; Retour
        </Button>
        <h1>{course.name?.replace(/\d+/g, "") ?? course.name}</h1>
        <p>{course.description}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
          c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
          c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
            fill="white"
          ></path>
        </svg>
      </div>
      <div className="filter-list">
        {listFilter.map((filter, idx) => (
          <div
            key={`filter-item-${idx}`}
            className={activeFilter === filter.name ? "active" : ""}
            onClick={() => setActiveFilter(filter.name)}
          >
            {filter.name}
          </div>
        ))}
      </div>
      <div id="search-input">
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={onSearchChange}
        />
      </div>
      <div className="container">
        {isLoaded &&
          (activeFilter === "Tout"
            ? _.uniqBy(
                course.modules
                  .filter(
                    (module: any) =>
                      !["médias mensuels", "articles", "lives"].includes(
                        module.name.toLowerCase()
                      )
                  )
                  .map((module: any) => module.lessons)
                  .flat(),
                "id"
              )
            : _.uniqBy(
                course.modules.find(
                  (module: any) => module.name === activeFilter
                ).lessons,
                "id"
              )
          )
            .filter((lesson: any) =>
              normalizeString(lesson.name).includes(normalizeString(searchTerm))
            )
            .map((lesson: any, index: number) => (
              <LessonCard
                key={`lesson-card-${index}`}
                illustration={lesson?.preview || "/images/preview_default.png"}
                title={highlightText(lesson.name, searchTerm)}
                subtitle={lesson?.description}
                link={`/lessons/${lesson.id}`}
              />
            ))}
      </div>
    </div>
  );
};

export default Course;
