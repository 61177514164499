import { useLocation, useNavigate } from "react-router-dom";

/**
 * Permits to go back with a fallback if the previous location is out of our web application
 * @returns void
 * @example
const goBack = useGoBack()
// example with default fallback
onClick={() => goBack()}
// example with custom fallback
onClick={() => goBack({fallBack: "/courses"})}
 */
export const useGoBack = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.key !== "default") {
    return (_arg?: { fallback?: string }) => navigate(-1);
  } else {
    return ({ fallback }: { fallback?: string } = {}) =>
      navigate(fallback || "/");
  }
};

/**
 * Permits to enable react-dom navigation if inside of the react-app, or use usual window navigation otherwise
 * @param event 
 * @param navigate 
 * @param path 
 * @example
completeNavigate(event, navigate, "/")// will use the navigate(['/])
completeNavigate(event, navigate, "https://www.google.com")// will use the window open (which will permit the ctrl+click)
 */
export const completeNavigate = (
  event: React.MouseEvent,
  navigate: Function,
  path: string
) => {
  if (event.ctrlKey || event.metaKey) {
    window.open(path, "_blank", "noopener");
  } else if (path.slice(0, 4) === "http") {
    window.open(path);
  } else {
    navigate(path);
  }
};
