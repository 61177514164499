import Card from "../../components/Card/Card";
import HeadbandBellyPremium from "../../components/HeadbandBellyPremium/HeadbandBellyPremium";
import LeftPanel from "../../components/LeftPanel/LeftPanel";
import TemporaryPromotion from "../../components/TemporaryPromotion/TemporaryPromotion";
// import Promotion from "../../components/Promotion/Promotion";
import WidgetPanel from "../../components/WidgetPanel/WidgetPanel";
import useWindowSize from "../../hooks/useWindowSize";
import ApiService from "../../services/ApiService";
import "./Alimentation.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Alimentation = () => {
  const navigate = useNavigate();
  const [alimentations, setAlimentations] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userAlimentation, setUserAlimentation] = useState<string[]>([]);
  const { width } = useWindowSize();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allCourses = await ApiService.get("e-learning/courses/");
        const userCoursesAnswer = await ApiService.get("users/courses");
        setAlimentations(
          allCourses.data.filter((courses: any) => courses.type === "nutrition")
        );
        setUserAlimentation(userCoursesAnswer.data);
        setIsLoaded(true);
      } catch (err) {
        toast.error(
          "Une erreur s'est produite lors de la récupération des données du cours. Veuillez réessayer plus tard."
        );
        console.error(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div id="alimentation">
      <HeadbandBellyPremium />
      <TemporaryPromotion />
      {/* <Promotion /> */}
      <section>
        <LeftPanel />
        <div id="main">
          <section>
            <div id="alimentation-header">
              <h2>Alimentation</h2>
            </div>
            <div id="alimentation-list">
              {isLoaded ? (
                alimentations.length ? (
                  alimentations.map((alim: any, index: number) => (
                    <Card
                      key={`card-${index}`}
                      img={
                        <img
                          src={alim.preview}
                          alt={`illustration-alim-${index}`}
                        />
                      }
                      title={alim.name}
                      action={() =>
                        userAlimentation.includes(alim.id)
                          ? navigate(`/courses/${alim.id}`)
                          : (document.location.href = alim?.purchase_url)
                      }
                      actionTitle={
                        userAlimentation.includes(alim.id)
                          ? "Reprendre"
                          : "Commencer"
                      }
                      disabled={!userAlimentation.includes(alim.id)}
                    />
                  ))
                ) : (
                  <div>
                    <p className="none-paragraph">
                      Vous ne possédez aucun programme alimentation pour le
                      moment.
                    </p>
                  </div>
                )
              ) : (
                <p>Chargement du programme TBL alimentation...</p>
              )}
            </div>
          </section>
          {width <= 1000 && <WidgetPanel />}
        </div>
        {width > 1000 && <WidgetPanel />}
      </section>
    </div>
  );
};

export default Alimentation;
