import ArrowBottom from "../../icons/Arrow/ArrowBottom";
import ArrowTop from "../../icons/Arrow/ArrowTop";
import Link from "../Link/Link";
import { MenuOptions } from "../Menu/Menu.d";
import "./Accordion.scss";
import { useState } from "react";

const ItemAccordion = ({
  accordion,
  left,
}: {
  accordion: MenuOptions[];
  left: number;
}) => {
  const [showMenu, setShowMenu] = useState(-1);

  return (
    <div className="items-accordion">
      {accordion.map((acc, idx) => (
        <div
          className="item-accordion"
          style={left > 0 ? { paddingLeft: left } : {}}
        >
          <div
            onClick={() =>
              acc.menu &&
              (showMenu === idx ? setShowMenu(-1) : setShowMenu(idx))
            }
          >
            <Link to={acc.redirect ?? ""}>
              <div>
                <div>{acc.title}</div>
                {acc.menu && acc.menu.length > 0 && (
                  <>{showMenu === idx ? <ArrowTop /> : <ArrowBottom />}</>
                )}
              </div>
            </Link>
          </div>
          {showMenu === idx && acc.menu && (
            <ItemAccordion accordion={acc.menu} left={left + 8} />
          )}
        </div>
      ))}
    </div>
  );
};

const Accordion = ({ accordion }: { accordion: MenuOptions[] }) => {
  return (
    <div className="accordion">
      <ItemAccordion accordion={accordion} left={0} />
    </div>
  );
};

export default Accordion;
