import { SVGProps } from "react";
import { JSX } from "react/jsx-runtime";

const DashboardIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      id="icone_espace"
      data-name="icone espace"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g id="element-4">
        <path
          id="Vector"
          d="M7.083,7.417V1.75C7.083.5,6.55,0,5.225,0H1.858C.533,0,0,.5,0,1.75V7.417c0,1.25.533,1.75,1.858,1.75H5.225C6.55,9.167,7.083,8.667,7.083,7.417Z"
          transform="translate(10.833 1.667)"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M7.083,1.75V7.417c0,1.25-.533,1.75-1.858,1.75H1.858C.533,9.167,0,8.667,0,7.417V1.75C0,.5.533,0,1.858,0H5.225C6.55,0,7.083.5,7.083,1.75Z"
          transform="translate(2.083 9.167)"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H20V20H0Z"
          transform="translate(20 20) rotate(180)"
          fill="none"
          opacity="0"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M7.083,4.083V1.75C7.083.5,6.55,0,5.225,0H1.858C.533,0,0,.5,0,1.75V4.083c0,1.25.533,1.75,1.858,1.75H5.225C6.55,5.833,7.083,5.333,7.083,4.083Z"
          transform="translate(10.833 12.5)"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M7.083,4.083V1.75C7.083.5,6.55,0,5.225,0H1.858C.533,0,0,.5,0,1.75V4.083c0,1.25.533,1.75,1.858,1.75H5.225C6.55,5.833,7.083,5.333,7.083,4.083Z"
          transform="translate(2.083 1.667)"
        />
      </g>
    </svg>
  );
};

export default DashboardIcon;
