import ArrowRight from "../../icons/Arrow/ArrowRight";
import ApiService from "../../services/ApiService";
import "./WidgetPanel.scss";
import { useEffect, useState } from "react";

const WidgetPanel = () => {
  const [streak, setStreak] = useState({
    current: 0,
    best: 0,
  });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    ApiService.get("e-learning/activity/streak/")
      .then((response) =>
        setStreak({
          current: response.data.actual_result,
          best: response.data.best_streak,
        })
      )
      .catch((err) => console.error(err))
      .finally(() => setLoaded(true));
  }, []);

  return (
    <div className="widget-bar">
      <p>Succès</p>
      <div id="streak-block" className={loaded ? "" : "loading"}>
        <p>
          {loaded && streak.current}{" "}
          <img src="/images/fire-streak.png" alt="fire-streak" />
        </p>
        <p>
          {loaded &&
            (streak.current > 1
              ? `Félicitations ! Vous vous êtes connectée ${streak.current} jours de suite, continuez sur cette lancée !`
              : "Continuez de vous connecter quotidiennement afin de suivre votre régularité.")}
        </p>
        {loaded &&
          (streak.best === streak.current ? (
            <p>C'est votre record !</p>
          ) : (
            <p>Précédent record : {streak.best} jours</p>
          ))}
      </div>
      <div id="achievements">
        <p className="no-blur">Bientôt disponible</p>
        <div className="list">
          {[...Array(4)].map((_, index: number) => (
            <img
              alt=""
              className="achievement"
              key={`achievement-mock-${index}`}
            />
          ))}
        </div>
      </div>
      <div id="good-plans">
        <p>Bonnes affaires</p>
        <div className="list">
          <a
            href="https://thebellylab.com/parrainage/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="good-plank-block">
              <div>
                <h3>Parrainages</h3>
                <p>10€ de réduction chacune</p>
              </div>
              <ArrowRight />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WidgetPanel;
