import { SVGProps } from "react";

const AppStoreSvg = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2.787 4c.18 1.637-1.283 3.345-2.806 3.226-.188-1.43 1.157-3.159 2.806-3.226zm-.2 13.981c-.938.018-1.238-.556-2.311-.556-1.071 0-1.406.539-2.293.574-1.5.057-3.816-3.4-3.816-6.414 0-2.769 1.93-4.142 3.615-4.167.903-.016 1.758.609 2.31.609.554 0 1.592-.752 2.682-.642.455.019 1.736.184 2.561 1.387-2.185 1.424-1.845 4.403.499 5.498-.459 1.331-1.832 3.686-3.247 3.711z" />
    </svg>
  );
};
export default AppStoreSvg;
