import { SVGProps } from "react";

const SecurityIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="13.846" height="18" viewBox="0 0 13.846 18" {...props}>
      <path
        id="icone_securité"
        data-name="icone securité"
        d="M18.865,9.605H17.827V7.585a4.154,4.154,0,1,0-8.308-.056V9.605H8.481A1.736,1.736,0,0,0,6.75,11.336v8.308a1.736,1.736,0,0,0,1.731,1.731H18.865A1.736,1.736,0,0,0,20.6,19.644V11.336A1.736,1.736,0,0,0,18.865,9.605Zm-4.586,5.4v2.977a.62.62,0,0,1-.575.623A.606.606,0,0,1,13.067,18V15.005a1.385,1.385,0,1,1,1.212,0Zm2.336-5.4H10.731V7.529a2.942,2.942,0,0,1,5.884,0Z"
        transform="translate(-6.75 -3.374)"
      />
    </svg>
  );
};

export default SecurityIcon;
