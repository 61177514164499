const FileVideoSVG = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="27" height="36" viewBox="0 0 27 36" {...props}>
      <path
        id="Icon_awesome-file-video"
        data-name="Icon awesome-file-video"
        d="M27,8.574V9H18V0h.426a1.688,1.688,0,0,1,1.193.494l6.886,6.886A1.688,1.688,0,0,1,27,8.574Zm-11.25.989V0H1.688A1.687,1.687,0,0,0,0,1.688V34.313A1.687,1.687,0,0,0,1.688,36H25.313A1.687,1.687,0,0,0,27,34.313V11.25H17.438A1.692,1.692,0,0,1,15.75,9.563ZM22.5,19.689v7.872a1.689,1.689,0,0,1-2.881,1.193L15.75,24.886v2.676a1.687,1.687,0,0,1-1.687,1.688H6.188A1.687,1.687,0,0,1,4.5,27.563V19.688A1.687,1.687,0,0,1,6.188,18h7.875a1.687,1.687,0,0,1,1.688,1.688v2.676L19.619,18.5A1.689,1.689,0,0,1,22.5,19.689Z"
        fill="#e90d2f"
      />
    </svg>
  );
};

export default FileVideoSVG;
