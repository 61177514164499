import Button from "../../components/Button/Button";
import { useUserSession } from "../../context/UserContext";
import EyeClose from "../../icons/Eye/EyeClose";
import EyeOpen from "../../icons/Eye/EyeOpen";
import { submitConnection } from "./Login.function";
import "./Login.scss";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const username = useRef() as React.MutableRefObject<HTMLInputElement>;
  const password = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [isFilled, setIsFilled] = useState(false);
  const navigate = useNavigate();
  const _enableButton = () =>
    setIsFilled(
      !!username.current.value.length && !!password.current.value.length
    );
  const { setLogged } = useUserSession();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <section id="login">
      <div id="login-modale">
        <img
          src="/images/logo-rond.png"
          alt="SilverScreenProf Logo"
          width={150}
        />
        <hr />
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitConnection(event, navigate, setLogged);
          }}
        >
          <label htmlFor="username">Adresse mail</label>
          <input
            onChange={() => _enableButton()}
            type="text"
            id="username"
            name="username"
            ref={username}
            autoCapitalize="off"
            autoCorrect="off"
            autoFocus
          />
          <label htmlFor="password">Mot de passe</label>
          <div className="input-password">
            <input
              onChange={() => _enableButton()}
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              ref={password}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              id="toggle-button"
            >
              {showPassword ? <EyeOpen /> : <EyeClose />}
            </button>
          </div>
          <Button category="primary" disabled={!isFilled} type="submit">
            Se connecter
          </Button>
          <a
            href="https://thebellylab.com/my-account/lost-password/"
            id="forgotten-password"
          >
            Mot de passe oublié ?
          </a>
        </form>
      </div>
    </section>
  );
};

export default Login;
