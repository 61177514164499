import CrossSVG from "../../../../icons/Cross";
import ApiService from "../../../../services/ApiService";
import { CourseProgressBarProps } from "./CourseProgressBar.d";
import { resetCourseProgress } from "./CourseProgressBar.function";
import "./CourseProgressBar.scss";
import React from "react";
import { useEffect, useState } from "react";

const CourseProgressBar = ({ courseId }: CourseProgressBarProps) => {
  const [loaded, setLoaded] = useState(false);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    ApiService.get(`e-learning/lesson-activity/courses/${courseId}`)
      .then((resp) => setPercentage(resp.data.ended_percent))
      .catch((err) => console.error(err))
      .finally(() => setLoaded(true));
  }, [courseId]);

  return loaded ? (
    <div className="progress-bar">
      <div className="progress-container">
        <div className="progress-text">
          <span>0%</span>
          {![0, 100].includes(percentage) && (
            <span
              className="progress-percentage"
              style={{ left: `${percentage}%` }}
            >
              {percentage}%
            </span>
          )}
          <span>100%</span>
        </div>
        <div className="progress-bar-content">
          <div
            className="progress-fill"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <div className="button-restart">
          <button onClick={() => resetCourseProgress(courseId, setPercentage)}>
            <p>Recommencer</p>
            <CrossSVG />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default CourseProgressBar;
