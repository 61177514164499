import { UpcomingCardProps } from "./UpcomingCard.d";
import "./UpcomingCard.scss";

const UpcomingCard = ({
  title,
  icon,
  description,
  subtitle,
  children,
}: UpcomingCardProps) => {
  return (
    <div className="upcoming-card">
      <div>
        {icon}
        <div>
          {title}
          {subtitle}
          {description}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default UpcomingCard;
