import useShowDiv from "../../hooks/useShowBellyPremium";
import ApiService from "../../services/ApiService";
import "./HeadbandBellyPremium.scss";
import { useEffect, useState } from "react";

// import { toast } from "react-toastify";

const HeadbandBellyPremium = () => {
  const showDiv = useShowDiv();
  const [liveData, setLiveData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const coursesAnswer = await ApiService.get(
          "e-learning/courses/50005692-f443-425e-8994-1c2ebf632c19"
        );
        const infos = coursesAnswer.data.lessons.filter((l: any) =>
          l.modules.includes(coursesAnswer.data.modules[0].name)
        );
        setLiveData(
          infos.find(
            (info: any) =>
              info.lesson_type === "link" &&
              info.name === "Voir le dernier live en replay"
          )
        );
      } catch (err) {
        // toast.error(
        //   "Une erreur s'est produite lors de la récupération des données du cours. Veuillez réessayer plus tard."
        // );
        console.error(err);
      }
    };
    fetchData();
  }, []);

  return showDiv ? (
    <div id="headband">
      <div>
        <p>
          <b>C'est maintenant : </b>Votre live Belly Premium <b>à 18h !</b>
        </p>
      </div>
      <button>
        <a href={liveData?.source}>VOIR LE LIVE</a>
      </button>
    </div>
  ) : (
    <></>
  );
};

export default HeadbandBellyPremium;
