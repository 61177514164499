import App from "./app/App";
import "./index.css";
import { reportWebVitals } from "./reportWebVitals";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = createRoot(document.getElementById("root")!);
root.render(
  <Suspense fallback={<span>loading...</span>}>
    <React.StrictMode>
      <App />
      <ToastContainer />
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
