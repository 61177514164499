import { checkToken } from "../services/AccessTokenService";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const location = useLocation();
  const from = { from: location };

  if (!checkToken()) {
    return <Navigate to="/login" state={from} replace />;
  }

  return <>{props.children}</>;
};
