import { Link as LinkRouter } from "react-router-dom";
import styled from "styled-components";

const Link = styled(LinkRouter)`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export default Link;
