import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";

export const saveSurveyAnswer = (
  survey_id: string,
  answer: string,
  callback: Function
) => {
  ApiService.post(`e-learning/surveys/${survey_id}`, { answer })
    .then(() => callback())
    .catch((err) => console.error(err));
};

export const fetchSurvey = async (
  setSurvey: Function,
  setSelectedCheckbox: Function,
  setSurveyLoaded: Function
) => {
  try {
    const surveysAnswer = await ApiService.get("e-learning/surveys/");

    setSurvey(surveysAnswer.data);
    if (surveysAnswer.data?.chosen_answer) {
      setSelectedCheckbox(surveysAnswer.data?.chosen_answer);
    }
  } catch (err) {
    toast.error(
      "Une erreur s'est produite lors de la récupération du quiz du mois. Veuillez réessayer plus tard."
    );
    console.error(err);
  } finally {
    setSurveyLoaded(true);
  }
};
