import ApiService from "../../../../services/ApiService";
import { toast } from "react-toastify";

export const resetCourseProgress = (
  courseId: string,
  setPercentage: Function
) => {
  ApiService.delete(`e-learning/lesson-activity/courses/${courseId}`)
    .then(() => setPercentage(0))
    .catch((err) => {
      console.error(err);
      toast.error(
        "Une erreur s'est produite lors de la réinitialisation de votre progression dans ce cours. Veuillez réessayer plus tard."
      );
    });
};
