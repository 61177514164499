import { TypeBellyPremiumCard } from "./BellyPremiumCard.d";
import "./BellyPremiumCard.scss";

const BellyPremiumCard = ({
  illustration,
  title,
  action,
  icon,
}: TypeBellyPremiumCard) => {
  return (
    <div className="bellypremium-card" onClick={() => action?.()}>
      {icon}
      <div className="img-div">
        <img src={illustration} alt={`img-${title}`} />
      </div>
      <div>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default BellyPremiumCard;
