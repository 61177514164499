import { UserContextType, UserProviderProps } from "./UserContext.d";
import { fetchUserData, getInitialState } from "./UserContext.function";
import { createContext, useState, useEffect } from "react";
import React from "react";

export const UserContext = createContext<UserContextType>(getInitialState());

export const UserProvider = ({ children }: UserProviderProps) => {
  const [value, setValue] = useState(getInitialState());

  const setLogged = (logged: boolean) =>
    setValue((value) => ({ ...value, logged }));

  useEffect(() => {
    setValue((value) => ({ ...value, setLogged }));
    if (value.logged) {
      fetchUserData(setValue);
    }
  }, [value.logged]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserSession = () => React.useContext(UserContext);
