import { SVGProps } from "react";

const CreditCardIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.286"
      height="15"
      viewBox="0 0 19.286 15"
      {...props}
    >
      <path
        id="Icon_awesome-credit-card"
        data-name="Icon awesome-credit-card"
        d="M0,15.643A1.608,1.608,0,0,0,1.607,17.25H17.679a1.608,1.608,0,0,0,1.607-1.607V9.75H0Zm6.429-2.277a.4.4,0,0,1,.4-.4h4.554a.4.4,0,0,1,.4.4v1.339a.4.4,0,0,1-.4.4H6.83a.4.4,0,0,1-.4-.4Zm-4.286,0a.4.4,0,0,1,.4-.4H4.955a.4.4,0,0,1,.4.4v1.339a.4.4,0,0,1-.4.4H2.545a.4.4,0,0,1-.4-.4ZM19.286,3.857V5.464H0V3.857A1.608,1.608,0,0,1,1.607,2.25H17.679A1.608,1.608,0,0,1,19.286,3.857Z"
        transform="translate(0 -2.25)"
      />
    </svg>
  );
};

export default CreditCardIcon;
