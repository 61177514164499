const PlayCircleSVG = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="40.099" height="40.099" viewBox="0 0 40.099 40.099" {...props}>
      <path
        id="Icon_awesome-play-circle"
        data-name="Icon awesome-play-circle"
        d="M20.612.563A20.049,20.049,0,1,0,40.661,20.612,20.046,20.046,0,0,0,20.612.563Zm9.354,21.99L15.737,30.717a1.944,1.944,0,0,1-2.886-1.7V12.2a1.945,1.945,0,0,1,2.886-1.7l14.229,8.65A1.946,1.946,0,0,1,29.965,22.552Z"
        transform="translate(-0.563 -0.563)"
        fill="#e90d2f"
      />
    </svg>
  );
};

export default PlayCircleSVG;
