import Play from "../../../../icons/Play";
import { LessonCardV2Options } from "./LessonCardV2.d";
import "./LessonCardV2.scss";
import { useNavigate } from "react-router-dom";

/**
 * Lesson Card V2 component containing an illustration, a title, a subtitle, permitting user to reach its lesson
 *
 * @component
 * @property {string} illustration Picture URL
 * @property {string} title Lesson Card title
 * @property {string} link Link of the option
 */
const LessonCardV2 = ({ illustration, title, link }: LessonCardV2Options) => {
  const navigate = useNavigate();

  return (
    <div id="lesson-card-v2" onClick={() => navigate(link)}>
      <div>
        <div id="lesson-img">
          <img src={illustration} alt="Lesson illustration" />
        </div>
        <div id="title-div">
          <div>
            {title
              .split(":")
              .map((t, idx) => (idx === 0 ? <b>{t}</b> : <span>:{t}</span>))}
          </div>
        </div>
      </div>
      <div id="player-section">
        <div>
          <Play color="black" />
        </div>
      </div>
    </div>
  );
};

export default LessonCardV2;
