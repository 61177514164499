import Play from "../../../../icons/Play";
import { LessonCardChallengeOptions } from "./LessonCardChallenge.d";
import "./LessonCardChallenge.scss";
import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * Lesson Card Challenge component containing an illustration, a title, a subtitle, permitting user to reach its lesson
 *
 * @component
 * @property {string} illustration Picture URL
 * @property {string} title Lesson Card title
 * @property {string} link Link of the option
 */
const LessonCardChallenge = ({
  illustration,
  title,
  link,
}: LessonCardChallengeOptions) => {
  const navigate = useNavigate();

  return (
    <div className="lesson-card-challenge" onClick={() => navigate(link)}>
      <div>
        <div className="lesson-card-challenge-image">
          <img src={illustration} alt="Lesson illustration" />
        </div>
        <div className="lesson-card-challenge-title">
          <div>
            {title.split(":").map((t: any, idx: number) => (
              <React.Fragment key={`lesson-card-challenge-title-line-${idx}`}>
                {idx === 0 ? <b>{t}</b> : <span>:{t}</span>}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="lesson-card-challenge-player-section">
        <div>
          <Play color="black" />
        </div>
      </div>
    </div>
  );
};

export default LessonCardChallenge;
