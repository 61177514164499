import Button from "../../../../components/Button/Button";
import { NewsCardProps } from "./NewsCard.d";
import "./NewsCard.scss";
import { useCallback } from "react";

const NewsCard = ({ type, illustration, title, link }: NewsCardProps) => {
  const action = useCallback(() => (document.location.href = link), [link]);

  return (
    <div className="news-card" onClick={action}>
      <div
        className="type-card"
        style={{
          backgroundImage: `${
            type === "seance"
              ? "linear-gradient(to bottom, #fbb6b9, #f78da7)"
              : "linear-gradient(to bottom, #39DCF2, #0693e3)"
          }`,
        }}
      >
        {type.toUpperCase()}
      </div>
      <div>
        <img src={illustration} alt={title} />
      </div>
      <p>{title}</p>
      <Button category="primary">Voir</Button>
    </div>
  );
};

export default NewsCard;
