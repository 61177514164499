import { CardOptions } from "./Card.d";
import "./Card.scss";
import React from "react";
import { useEffect, useRef, useState } from "react";

/**
 * Card component containing a picture, a title, optionnally a subtitle, and a action button
 *
 * @component
 * @property {JSX.Element} img Picture DOM element
 * @property {string} title Card title
 * @property {string} subtitle Card additional text (optional)
 * @property {Function} action Function to trigger on click on the action button (optional)
 * @property {string} actionTitle Action button title (default: "Continuer")
 * @property {boolean} disabled Disabled card (default: false)
 */
const Card = ({
  img,
  title,
  subtitle,
  action,
  actionTitle = "Continuer",
  disabled = false,
}: CardOptions): JSX.Element => {
  const [imgDim, setImgDim] = useState({ width: 0, height: 0 });
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const image = imageRef.current;

    const handleLoad = () => {
      if (image) {
        setImgDim({ width: image.clientWidth, height: image.clientHeight });
      }
    };

    if (image && image.complete) {
      handleLoad();
    } else if (image) {
      image.addEventListener("load", handleLoad);
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [img]);

  return (
    <div className="card" onClick={() => action?.()}>
      {disabled && (
        <div
          className="inactive"
          style={{
            ...imgDim,
          }}
        >
          <div>Obtenir</div>
        </div>
      )}
      {React.cloneElement(img, { ref: imageRef })}
      <div>
        <div className="title">{title.split(":")?.[1] ?? title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
        {action && (
          <div className="action" onClick={() => action()}>
            {actionTitle}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
