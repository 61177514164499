import Play from "../../icons/Play";
import { LessonCardOptions } from "./LessonCard.d";
import "./LessonCard.scss";
import { Link } from "react-router-dom";

/**
 * Lesson Card component containing an illustration, a title, a subtitle, permitting user to reach its lesson
 *
 * @component
 * @property {string} illustration Picture URL
 * @property {string} title Lesson Card title
 * @property {string} subtitle Lesson Card additional text
 * @property {string} link Link of the option
 * @property {string} width Lesson Card maximum width (default: "300px")
 */
const LessonCard = ({
  illustration,
  title,
  subtitle,
  link,
  width = "300px",
}: LessonCardOptions): JSX.Element => (
  <div
    className="lesson-card"
    style={{
      width,
    }}
  >
    <div className="image-section">
      <Link to={link}>
        <img src={illustration} alt="Lesson illustration" />
        <div className="player-section">
          <div>
            <Play classes="" color="white" />
          </div>
        </div>
      </Link>
    </div>
    <div>
      <div className="title">
        <b>{title}</b>
      </div>
      <div className="subtitle">{subtitle}</div>
    </div>
  </div>
);

export default LessonCard;
