import { SVGProps } from "react";

const CommandIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.864"
      height="18"
      viewBox="0 0 20.864 18"
      {...props}
    >
      <path
        id="icone_commande"
        data-name="icone commande"
        d="M19.229,11.787,15.075,5.57a.922.922,0,0,0-.787-.4.934.934,0,0,0-.787.4L9.347,11.787H4.8a.951.951,0,0,0-.948.948,1.113,1.113,0,0,0,.033.256l2.4,8.791a1.9,1.9,0,0,0,1.83,1.385H20.452a1.89,1.89,0,0,0,1.826-1.389l2.4-8.791a.846.846,0,0,0,.038-.251.951.951,0,0,0-.948-.948H19.229Zm-7.786,0,2.845-4.173,2.845,4.173h-5.69Zm2.845,7.587a1.9,1.9,0,1,1,1.9-1.9A1.9,1.9,0,0,1,14.288,19.374Z"
        transform="translate(-3.856 -5.167)"
      />
    </svg>
  );
};

export default CommandIcon;
