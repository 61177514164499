import { UserContext } from "../../context/UserContext";
import AbonnementIcon from "../../icons/Abonnement";
import AlimentationIcon from "../../icons/Alimentation";
import ChallengesIcon from "../../icons/Challenges";
import CommandIcon from "../../icons/Command";
import CreditCardIcon from "../../icons/CreditCard";
import DashboardIcon from "../../icons/Dashboard";
import LogoutIcon from "../../icons/Logout";
import ProgressionIcon from "../../icons/Progression";
import SecurityIcon from "../../icons/Security";
import AppStoreSvg from "../../icons/SocialNetworks/AppStore";
import FacebookSvg from "../../icons/SocialNetworks/Facebook";
import GooglePlaySvg from "../../icons/SocialNetworks/GooglePlay";
import InstagramSvg from "../../icons/SocialNetworks/Instagram";
import PinterestSvg from "../../icons/SocialNetworks/Pinterest";
import TikTokSvg from "../../icons/SocialNetworks/TikTok";
import YoutubeSvg from "../../icons/SocialNetworks/Youtube";
import TrainingIcon from "../../icons/Training";
import { completeNavigate } from "../../services/NavigationService";
import Button from "../Button/Button";
import "./LeftPanel.scss";
import { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type ButtonList = {
  title: string;
  type: string;
  redirect: string;
  icon: JSX.Element;
}[];

type FooterButton = {
  title: string;
  type: string;
  function: () => void;
  icon: JSX.Element;
}[];

const LeftPanel = () => {
  let { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, setLogged } = useContext(UserContext);

  const buttonList: ButtonList = useMemo(
    () => [
      {
        title: "mon espace",
        type: "homepage",
        redirect: "/",
        icon: <DashboardIcon />,
      },
      {
        title: "entrainements",
        type: "entrainements",
        redirect: "/training",
        icon: <TrainingIcon />,
      },
      {
        title: "alimentation",
        type: "alimentation",
        redirect: "/alimentation",
        icon: <AlimentationIcon />,
      },
      {
        title: "progression (bientôt disponible)",
        type: "progression",
        redirect: "/progression",
        icon: <ProgressionIcon />,
      },
      {
        title: "challenges",
        type: "challenges",
        redirect: "/challenges",
        icon: <ChallengesIcon />,
      },
    ],
    []
  );

  const footerButtons: FooterButton = useMemo(
    () => [
      {
        title: "Commandes",
        type: "commandes",
        function: () => {
          document.location.href = "https://thebellylab.com/my-account/orders/";
        },
        icon: <CommandIcon />,
      },
      {
        title: "Moyen de paiements",
        type: "paiements",
        function: () => {
          document.location.href =
            "https://thebellylab.com/my-account/payment-methods/";
        },
        icon: <CreditCardIcon />,
      },
      {
        title: "Gérer mon abonnement",
        type: "abonnement",
        function: () => {
          document.location.href =
            "https://thebellylab.com/my-account/view-subscription/";
        },
        icon: <AbonnementIcon />,
      },
      {
        title: "Confidentialité & sécurité",
        type: "cgu",
        function: () => {
          document.location.href =
            "https://thebellylab.com/my-account/edit-account/";
        },
        icon: <SecurityIcon />,
      },
      {
        title: "Déconnexion",
        type: "deconnexion",
        function: () => {
          localStorage.clear();
          setLogged(false);
          navigate("/");
        },
        icon: <LogoutIcon />,
      },
    ],
    [navigate, setLogged]
  );

  const socialNetworks = useMemo(
    () => [
      {
        icon: <FacebookSvg />,
        link: "https://www.facebook.com/theBellylabJoelleBildstein/",
      },
      {
        icon: <InstagramSvg />,
        link: "https://www.instagram.com/thebellylab/",
      },
      {
        icon: <PinterestSvg />,
        link: "https://www.pinterest.fr/thebellylab/",
      },
      {
        icon: <TikTokSvg />,
        link: "https://www.tiktok.com/@the_belly_lab",
      },
      {
        icon: <YoutubeSvg />,
        link: "https://www.youtube.com/channel/UCF2DlfvB916jVLeATc4waag",
      },
    ],
    []
  );

  const initials = useMemo(() => {
    return user?.username
      .split(" ")
      .map((word) => word[0])
      .join("");
  }, [user?.username]);

  return (
    <div className="left-panel">
      <div>
        <div className="profile-container">
          <div className="profile">{initials}</div>
          <div className="profile-name">
            <div>Bienvenue {user?.username?.split("@")[0] ?? ""}</div>
            <div>
              <a
                href="https://thebellylab.com/my-account/edit-account/"
                target="_blank"
                rel="noreferrer"
              >
                Mon profil
              </a>
            </div>
          </div>
        </div>
        <div className="button-navigation">
          {buttonList.map((btn, index) => (
            <div
              className="button-container"
              key={`left-panel-button-${index}`}
            >
              <Button
                title={btn.title}
                icon={btn.icon}
                category={pathname === btn.redirect ? "primary" : "transparent"}
                onClick={() => navigate(btn.redirect)}
                textAlign="left"
              >
                {btn.title}
              </Button>
            </div>
          ))}
        </div>
      </div>
      <div className="buttons-footer">
        {footerButtons.map((btn, index) => (
          <div
            className="button-footer-container"
            key={`left-panel-footer-button-${index}`}
          >
            <Button
              title={btn.title}
              icon={btn.icon}
              category="transparent"
              onClick={btn.function}
            >
              {btn.title}
            </Button>
          </div>
        ))}
      </div>
      <div className="links-tbl">
        <p>Retrouvez-moi sur mes réseaux :</p>
        <div className="social-networks">
          {socialNetworks.map((socialNetwork, index) => (
            <div
              key={`social-network-${index}`}
              onClick={(e) => completeNavigate(e, navigate, socialNetwork.link)}
            >
              {socialNetwork.icon}
            </div>
          ))}
        </div>
        <p>Téléchargez l’application gratuite TBL :</p>
        <div className="applications">
          <div
            onClick={() =>
              (document.location.href =
                "https://apps.apple.com/fr/app/the-belly-lab/id1578192734")
            }
          >
            <AppStoreSvg />
          </div>
          <div
            onClick={() =>
              (document.location.href =
                "https://play.google.com/store/apps/details?id=fr.catsdigital.thebellylab&hl=fr&gl=US")
            }
          >
            <GooglePlaySvg />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
