import Card from "../../components/Card/Card";
import HeadbandBellyPremium from "../../components/HeadbandBellyPremium/HeadbandBellyPremium";
import LeftPanel from "../../components/LeftPanel/LeftPanel";
import TemporaryPromotion from "../../components/TemporaryPromotion/TemporaryPromotion";
// import Promotion from "../../components/Promotion/Promotion";
import WidgetPanel from "../../components/WidgetPanel/WidgetPanel";
import useWindowSize from "../../hooks/useWindowSize";
import ApiService from "../../services/ApiService";
import { translateCourseType } from "../../services/TranslateService";
import "./Home.scss";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Home = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState<any>([]);
  const [userCourses, setUserCourses] = useState<string[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const coursesAnswer = await ApiService.get(
          "e-learning/courses/?sort=activity"
        );
        const userCoursesAnswer = await ApiService.get("users/courses");
        setCourses(coursesAnswer.data);
        setUserCourses(userCoursesAnswer.data);
        setIsLoaded(true);
      } catch (err) {
        toast.error(
          "Une erreur s'est produite lors de la récupération des données du cours. Veuillez réessayer plus tard."
        );
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const sentenceMore = useMemo(
    () => (viewMore ? "Voir moins" : `Tout voir (${userCourses.length})`),
    [viewMore, userCourses.length]
  );

  return (
    <div id="home">
      <HeadbandBellyPremium />
      <TemporaryPromotion />
      {/* <Promotion /> */}
      <section>
        <LeftPanel />
        <div className="main">
          <section>
            <div id="courses-header">
              <h2>Reprendre</h2>
              <div className="more" onClick={() => setViewMore(!viewMore)}>
                {sentenceMore}
              </div>
            </div>
            <div id="courses-list">
              {isLoaded ? (
                userCourses.length ? (
                  courses
                    .filter((course: any) => userCourses.includes(course.id))
                    .filter((_course: any, idx: number) => viewMore || idx < 3)
                    .map((course: any, index: number) => (
                      <Card
                        key={`card-${index}`}
                        img={
                          <img
                            src={course.preview}
                            alt={`illustration-course-${index}`}
                          />
                        }
                        title={course.name}
                        subtitle={translateCourseType(course.type)}
                        action={() =>
                          navigate(
                            course.type === "subscription"
                              ? "belly-premium"
                              : `courses/${course.id}`
                          )
                        }
                        actionTitle="Reprendre"
                      />
                    ))
                ) : (
                  <p className="none-paragraph">
                    Vous ne possédez aucun programme pour le moment.
                  </p>
                )
              ) : (
                <p className="none-paragraph">
                  Chargement de vos programmes...
                </p>
              )}
            </div>
          </section>
          {width <= 1000 && <WidgetPanel />}
        </div>
        {width > 1000 && <WidgetPanel />}
      </section>
    </div>
  );
};

export default Home;
