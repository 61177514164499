import { checkToken } from "../services/AccessTokenService";
import ApiService from "../services/ApiService";
import { UserContextType } from "./UserContext.d";
import { toast } from "react-toastify";

export const getInitialState = (): UserContextType => ({
  logged: checkToken(),
  user: null,
  haveBellyPremium: false,
  setLogged: () => {},
});

export const fetchUserData = (setValue: Function) => {
  ApiService.get("users/me")
    .then((resp) => {
      ApiService.get("users/courses").then((res) => {
        const userCourses = res.data;
        setValue((value: UserContextType) => ({
          ...value,
          user: resp.data,
          haveBellyPremium: userCourses.includes(
            "50005692-f443-425e-8994-1c2ebf632c19"
          ),
        }));
      });
    })
    .catch((err) => {
      console.error("An error occurred with fetching user's data", err);
      toast.error(
        "Une erreur s'est produite lors de l'authentification, veuillez réessayer ultérieurement."
      );
    });
};
