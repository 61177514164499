const Cart = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="22" height="21.091" viewBox="0 0 22 21.091" {...props}>
      <g
        id="Icon_feather-shopping-cart"
        data-name="Icon feather-shopping-cart"
        transform="translate(-0.5 -0.5)"
      >
        <path
          id="Tracé_1"
          data-name="Tracé 1"
          d="M15,31.5A1.5,1.5,0,1,1,13.5,30,1.5,1.5,0,0,1,15,31.5Z"
          transform="translate(-4.55 -12.409)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Tracé_2"
          data-name="Tracé 2"
          d="M31.5,31.5A1.5,1.5,0,1,1,30,30,1.5,1.5,0,0,1,31.5,31.5Z"
          transform="translate(-13 -12.409)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Tracé_3"
          data-name="Tracé 3"
          d="M1.5,1.5H5.136L7.573,13.064a1.8,1.8,0,0,0,1.818,1.39h8.836a1.8,1.8,0,0,0,1.818-1.39L21.5,5.818H6.045"
          transform="translate(0 0)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default Cart;
