import useWindowSize from "../../hooks/useWindowSize";
import Cart from "../../icons/Cart";
import CrossSVG from "../../icons/Cross";
import MenuSVG from "../../icons/Menu";
import Accordion from "../Accordion/Accordion";
import Link from "../Link/Link";
import Menu from "../Menu/Menu";
import { MenuOptions } from "../Menu/Menu.d";
import "./Header.scss";
import { useState } from "react";

const menuHeaderLeft: MenuOptions[] = [
  {
    title: "Boutique",
    menu: [
      {
        title: "Programmes",
        submenu: [
          {
            title: "Bases de la méthode",
            redirect:
              "https://thebellylab.com/programme-1-fondement-de-la-methode/",
          },
          {
            title: "Anti mal de dos",
            redirect: "https://thebellylab.com/tbl-anti-mal-de-dos/",
          },
          {
            title: "Stretching",
            redirect: "https://thebellylab.com/programme-03-tbl-stretching/",
          },
          {
            title: "Gainage",
            redirect: "https://thebellylab.com/programme-4-tbl-gainage/",
          },
          {
            title: "Alimentation",
            redirect:
              "https://thebellylab.com/programme-alimentation-05-recettes-et-astuces-alimentaires-de-joelle/",
          },
          {
            title: "Carte cadeau",
            redirect: "https://thebellylab.com/cartes-cadeaux/",
          },
        ],
      },
      {
        title: "Abonnement",
        redirect: "https://thebellylab.com/belly-premium/",
      },
    ],
  },
  {
    title: "Témoignages",
    menu: [
      {
        title: "Avis",
        redirect: "https://thebellylab.com/temoignages/",
      },
      {
        title: "Avant / Après",
        redirect: "https://thebellylab.com/avant-apres/",
      },
      {
        title: "Success stories",
        redirect: "https://thebellylab.com/success-stories/",
      },
    ],
  },
];

const menuHeaderRight: MenuOptions[] = [
  {
    title: "TBL",
    menu: [
      {
        title: "Qu’est-ce que le concept The Belly Lab ?",
        redirect:
          "https://thebellylab.com/quest-ce-que-le-concept-the-belly-lab/",
      },
      {
        title: "Même si je ne suis pas sportive, pourquoi TBL ?",
        redirect:
          "https://thebellylab.com/tbl-meme-si-je-ne-suis-pas-sportive/",
      },
      {
        title: "Qui est Joëlle, la fondatrice The Belly Lab ?",
        redirect:
          "https://thebellylab.com/qui-est-joelle-la-fondatrice-de-tbl/",
      },
      {
        title: "TBL, on en parle ! (dans les médias)",
        redirect: "https://thebellylab.com/tbl-on-en-parle/",
      },
    ],
  },
  {
    title: "Blog",
    redirect:
      "https://thebellylab.com/blog-tbl-toutes-les-actualites-ventre-plat/",
  },
  {
    title: "Contact",
    redirect: "https://thebellylab.com/foire-aux-questions-et-support/",
  },
];

const HeaderDesktop = () => {
  const menuHeaderRightDesktop = [
    ...menuHeaderRight,
    {
      title: <Cart />,
      redirect: "https://thebellylab.com/panier/",
    },
  ];
  return (
    <div className="header-desktop">
      <div>
        <div className="header-menu">
          {menuHeaderLeft.map((menu, index: number) => (
            <Menu
              key={`menu-left-${index}`}
              title={menu.title}
              redirect={menu.redirect}
              menu={menu.menu}
            />
          ))}
        </div>
        <div id="logo-header">
          <Link to="/">
            <img src="/images/logo-rond.png" alt="Logo" />
          </Link>
        </div>
        <div className="header-menu">
          {menuHeaderRightDesktop.map((menu, index: number) => (
            <Menu
              key={`menu-right-${index}`}
              title={menu.title}
              redirect={menu.redirect}
              menu={menu.menu}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const HeaderMobile = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="header-mobile">
      <div>
        <div id="logo-header">
          <Link to="/">
            <img src="/images/logo-horizontal.png" alt="Logo" />
          </Link>
        </div>
        <div className="list-svg">
          <div>
            <Cart />
          </div>
          <div onClick={() => setShowMenu(!showMenu)}>
            {showMenu ? <CrossSVG /> : <MenuSVG />}
          </div>
        </div>
      </div>
      {showMenu && <Accordion accordion={menuHeaderRight} />}
    </div>
  );
};

const Header = () => {
  const { width } = useWindowSize();
  return width > 880 ? <HeaderDesktop /> : <HeaderMobile />;
};

export default Header;
