import { SVGProps } from "react";

const AlimentationIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      id="alimentation_icone"
      data-name="alimentation icone"
      width="13.244"
      height="20"
      viewBox="0 0 13.244 20"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_89"
            data-name="Rectangle 89"
            width="13.244"
            height="20"
          />
        </clipPath>
      </defs>
      <g id="Groupe_31" data-name="Groupe 31" clipPath="url(#clip-path)">
        <path
          id="Tracé_22"
          data-name="Tracé 22"
          d="M13.244,15.738a4.235,4.235,0,0,0-.595-2.218,4.173,4.173,0,0,0-.8-.967,4.63,4.63,0,0,1-3.668-.746,4.456,4.456,0,0,0,3.448.435,4.432,4.432,0,0,0,1.406-1.417.294.294,0,0,0-.114-.406,4.085,4.085,0,0,0-2.739-.365,3.363,3.363,0,0,0-1.307.744,3.661,3.661,0,0,1,.771-1.151.294.294,0,0,0-.418-.412,4.273,4.273,0,0,0-1.151,2.135q-.2.015-.386.043a4.234,4.234,0,0,0-3.118,6.65,4.641,4.641,0,0,0,2.4,1.73,4.241,4.241,0,0,1-3.126-6.067,4.256,4.256,0,0,1,3.846-2.313V8.643A3.421,3.421,0,0,0,7.05,6.648L3.643,6.473l3.133-.161-.018-.02a2.839,2.839,0,0,1-.772-1.949V2.884L2.279,2.735l4.212-.169a.293.293,0,0,0,.014-.09V1.468A1.47,1.47,0,0,0,5.037,0H2.652A1.469,1.469,0,0,0,1.184,1.468V2.477a.294.294,0,0,0,.293.294H1.7V4.342A2.84,2.84,0,0,1,.931,6.292,3.423,3.423,0,0,0,0,8.643v9.888A1.469,1.469,0,0,0,1.467,20H6.221a1.454,1.454,0,0,0,.748-.205A5.181,5.181,0,0,0,8.51,20a4.969,4.969,0,0,0,2.9-.957,4.445,4.445,0,0,0,1.823-3.018c.009-.124.008-.237.008-.286"
        />
      </g>
    </svg>
  );
};

export default AlimentationIcon;
