import useShowDiv from "../../hooks/useShowBellyPremium";
import { TemporaryPromotionObject } from "./TemporaryPromotion.d";
import "./TemporaryPromotion.scss";
import React, { useEffect, useState } from "react";

const TEMPORARY_PROMOTIONS: Array<TemporaryPromotionObject> = [
  {
    name: "Black Friday",
    schedule: ["2024-11-28T23:00:00.000Z", "2024-12-02T23:00:00.000Z"],
    text: "BLACK FRIDAY : -50% sur tout le site (Jusqu’au 01 Décembre)",
    buttonTitle: "Voir les offres black friday",
    buttonLink: "https://thebellylab.com/nos-programmes/",
  },
  {
    name: "Advent Calendar",
    schedule: ["2024-12-02T23:00:00.000Z", "2024-12-24T23:00:00.000Z"],
    text: "Le calendrier de l'avent TBL est sorti ! 24 cadeau pour votre ventre et votre bien-être 100% gratuit !",
    buttonTitle: "Voir le calendrier",
    buttonLink: "https://thebellylab.com/calendrier-de-lavent-2024/",
  },
];

const TemporaryPromotion = () => {
  const showDiv = useShowDiv();
  const [temporaryPromotionValue, setTemporaryPromotionValue] =
    useState<TemporaryPromotionObject | null>(null);

  const _findTemporaryPromotion = () => {
    const now = new Date();

    return TEMPORARY_PROMOTIONS.find(
      (tempPromotion: TemporaryPromotionObject) => {
        const start = new Date(tempPromotion.schedule[0]);
        const end = new Date(tempPromotion.schedule[1]);
        return start <= now && now <= end ? tempPromotion : null;
      }
    );
  };

  useEffect(() => {
    setTemporaryPromotionValue(_findTemporaryPromotion() || null);
  }, []);

  return (
    <React.Fragment>
      {temporaryPromotionValue && !showDiv && (
        <div id="temporary-promotion">
          <div>
            <p>
              <b>{temporaryPromotionValue.text}</b>
            </p>
          </div>
          <button>
            <a href={temporaryPromotionValue.buttonLink}>
              {temporaryPromotionValue.buttonTitle}
            </a>
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default TemporaryPromotion;
