export const translateCourseType = (courseType: string) => {
  switch (courseType) {
    case "course":
      return "programme";
    case "subscription":
      return "abonnement";
    default:
      return courseType;
  }
};
