import { SVGProps } from "react";

const ChallengesIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      id="icone_challenges"
      data-name="icone challenges"
      width="18.592"
      height="20"
      viewBox="0 0 18.592 20"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_93"
            data-name="Rectangle 93"
            width="18.592"
            height="20"
          />
        </clipPath>
      </defs>
      <g id="Groupe_37" data-name="Groupe 37" clipPath="url(#clip-path)">
        <path
          id="Tracé_34"
          data-name="Tracé 34"
          d="M18.063,11.457a.717.717,0,0,0-1.348-.016,3.5,3.5,0,0,1-.228.508c.139-2.923-.81-3.892-1.728-4.83a8.161,8.161,0,0,1-.727-.813,3.551,3.551,0,0,1-.678-1.853.721.721,0,0,0-1.327-.3,5.523,5.523,0,0,0-.834,2.7q-.079.2-.222.038a6.164,6.164,0,0,1-.321-2.869C10.893,1.755,9.981.645,9.174.113a.722.722,0,0,0-1.1.672c.118,1.059-.428,1.828-1.478,2.876l-.886.872A7.882,7.882,0,0,0,3.8,9.07q-.072.164-.206.048A3.728,3.728,0,0,0,1.91,7.734.723.723,0,0,0,.947,8.6a5.534,5.534,0,0,1-.4,3.308c-.6,1.86-.988,4,.372,5.643a3.952,3.952,0,0,0,2.719,1.4V15.116a1,1,0,0,1,1-1h.843a8.017,8.017,0,0,1,1.5-5.11.659.659,0,0,1,1.193.465c-.223,1.313.031,2.1.756,2.346.769.258,1.467-.523,1.544-1.227a.664.664,0,0,1,1.056-.438A4.991,4.991,0,0,1,13,14.116h.94a1,1,0,0,1,1,1v3.818a3.934,3.934,0,0,0,2.121-.895c2.179-1.843,1.566-4.915,1.008-6.582"
          transform="translate(0 0)"
        />
        <path
          id="Tracé_35"
          data-name="Tracé 35"
          d="M113.651,365.194h-9.293a.479.479,0,0,0-.479.479v4.405a.479.479,0,0,0,.479.479h9.293a.479.479,0,0,0,.479-.479v-4.405a.479.479,0,0,0-.479-.479m-.453,4.343a.3.3,0,1,1-.6,0v-3.328a.3.3,0,0,1,.6,0Zm-1.044,0a.3.3,0,0,1-.6.046,1.418,1.418,0,1,1,0-2.31.3.3,0,0,1,.6.046Zm-5.943-2.483a.3.3,0,0,1,.38.465l-.908.742,1.056,1.068a.3.3,0,1,1-.427.422l-.905-.916v.7a.3.3,0,1,1-.6,0v-3.318a.3.3,0,1,1,.6,0v1.5Zm1.99,2.187a.632.632,0,0,0,.473-.208.3.3,0,0,1,.46.386,1.208,1.208,0,0,1-.933.422,1.416,1.416,0,1,1,.792-2.59.3.3,0,1,1-.336.5.816.816,0,1,0-.456,1.492"
          transform="translate(-99.715 -350.557)"
        />
        <path
          id="Tracé_36"
          data-name="Tracé 36"
          d="M255.374,425.377a.818.818,0,1,0,.818.818.819.819,0,0,0-.818-.818"
          transform="translate(-244.353 -408.328)"
        />
        <path
          id="Tracé_37"
          data-name="Tracé 37"
          d="M156.35,233.62c-.042-.022-.071-.011-.086.033a1.914,1.914,0,0,1-2.332,1.731c-.721-.242-1.506-.961-1.157-3.016q-.019-.1-.1-.035a7.413,7.413,0,0,0-1.4,4.781h6.3a4.522,4.522,0,0,0-1.234-3.494"
          transform="translate(-145.19 -222.998)"
        />
      </g>
    </svg>
  );
};

export default ChallengesIcon;
