const CheckCircleSVG = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="34.875" height="34.875" viewBox="0 0 34.875 34.875" {...props}>
      <path
        id="Icon_awesome-check-circle"
        data-name="Icon awesome-check-circle"
        d="M35.438,18A17.438,17.438,0,1,1,18,.563,17.437,17.437,0,0,1,35.438,18ZM15.983,27.233,28.921,14.3a1.125,1.125,0,0,0,0-1.591L27.33,11.114a1.125,1.125,0,0,0-1.591,0L15.188,21.665l-4.926-4.926a1.125,1.125,0,0,0-1.591,0L7.079,18.329a1.125,1.125,0,0,0,0,1.591l7.313,7.313a1.125,1.125,0,0,0,1.591,0Z"
        transform="translate(-0.563 -0.563)"
        fill="#e90d2f"
      />
    </svg>
  );
};

export default CheckCircleSVG;
