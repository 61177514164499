const Play = ({
  color = "grey",
  classes = "me-2",
}: {
  color?: string;
  classes?: string;
}) => {
  return (
    <svg className={classes} height="1em" viewBox="-2 0 15 16" fill={color}>
      <path
        d="M12.3659 6.87327C13.2114 7.37404 13.2114 8.62596 12.3659 9.12673L1.90244 15.3238C1.05691 15.8245 3.1118e-07 15.1986 3.54958e-07 14.197L8.96719e-07 1.80298C9.40498e-07 0.80144 1.05691 0.175477 1.90244 0.676246L12.3659 6.87327Z"
        fill="current"
      />
    </svg>
  );
};

export default Play;
