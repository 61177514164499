import { SVGProps } from "react";

const TrainingIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      id="entrainement_icone"
      data-name="entrainement icone"
      width="15.571"
      height="20.001"
      viewBox="0 0 15.571 20.001"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_88"
            data-name="Rectangle 88"
            width="15.571"
            height="20.001"
          />
        </clipPath>
      </defs>
      <g id="Groupe_29" data-name="Groupe 29" clipPath="url(#clip-path)">
        <path
          id="Tracé_19"
          data-name="Tracé 19"
          d="M145.286,147.458h-9.741v9.071h9.741Z"
          transform="translate(-129.715 -141.115)"
        />
        <path
          id="Tracé_20"
          data-name="Tracé 20"
          d="M5.83,16.925a.217.217,0,1,1-.434,0V4.831a.217.217,0,1,1,.434,0V5.938h9.741V3.226H5.807V2.9A2.9,2.9,0,0,0,0,2.9V17.1A2.907,2.907,0,0,0,2.9,20H15.571V15.818H5.83ZM2.9,18.606a1.5,1.5,0,1,1,1.5-1.5,1.5,1.5,0,0,1-1.5,1.5m2.22-3.341a.215.215,0,0,1-.3-.05,2.458,2.458,0,0,0-1.7-.994,2.389,2.389,0,0,0-2.16,1.05.215.215,0,0,1-.354-.244,2.82,2.82,0,0,1,2.553-1.234,2.854,2.854,0,0,1,2.013,1.173.215.215,0,0,1-.05.3m0-10.886a.215.215,0,0,1-.3-.05,2.459,2.459,0,0,0-1.7-.994A2.39,2.39,0,0,0,.96,4.385a.215.215,0,0,1-.354-.244A2.821,2.821,0,0,1,3.159,2.907,2.855,2.855,0,0,1,5.172,4.08a.215.215,0,0,1-.05.3"
        />
        <path
          id="Tracé_21"
          data-name="Tracé 21"
          d="M43.751,373.04a1.065,1.065,0,1,0,1.065,1.065,1.066,1.066,0,0,0-1.065-1.065"
          transform="translate(-40.85 -356.994)"
        />
      </g>
    </svg>
  );
};

export default TrainingIcon;
