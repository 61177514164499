import { SVGProps } from "react";

const LogoutIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      id="vuesax_bold_logout"
      data-name="vuesax/bold/logout"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g id="logout">
        <path
          id="Vector"
          d="M7.8,0H5.2A4.832,4.832,0,0,0,0,5.2V9.25H6.25a.75.75,0,0,1,0,1.5H0V14.8A4.832,4.832,0,0,0,5.2,20H7.79a4.832,4.832,0,0,0,5.2-5.2V5.2A4.819,4.819,0,0,0,7.8,0Z"
          transform="translate(9 2)"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M2.557,3.348l2.07-2.07a.742.742,0,0,0,.22-.53.725.725,0,0,0-.22-.53.754.754,0,0,0-1.06,0L.218,3.568a.754.754,0,0,0,0,1.06l3.35,3.35a.75.75,0,0,0,1.06-1.06l-2.07-2.07H7v-1.5Z"
          transform="translate(2.003 7.902)"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          transform="translate(24 24) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};

export default LogoutIcon;
