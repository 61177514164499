import { SVGProps } from "react";

const AbonnementIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.996"
      height="17"
      viewBox="0 0 16.996 17"
      {...props}
    >
      <path
        id="icone_gestion_abonnement"
        data-name="icone gestion abonnement"
        d="M20.093,13a2.187,2.187,0,0,1,1.4-2.04A8.669,8.669,0,0,0,20.447,8.43a2.216,2.216,0,0,1-.89.19,2.182,2.182,0,0,1-2-3.072A8.643,8.643,0,0,0,15.038,4.5a2.185,2.185,0,0,1-4.081,0A8.669,8.669,0,0,0,8.43,5.549a2.182,2.182,0,0,1-2,3.072,2.144,2.144,0,0,1-.89-.19A8.86,8.86,0,0,0,4.5,10.962a2.186,2.186,0,0,1,0,4.081A8.669,8.669,0,0,0,5.553,17.57a2.183,2.183,0,0,1,2.881,2.881A8.719,8.719,0,0,0,10.962,21.5a2.181,2.181,0,0,1,4.072,0,8.669,8.669,0,0,0,2.527-1.049,2.185,2.185,0,0,1,2.881-2.881,8.719,8.719,0,0,0,1.049-2.527A2.2,2.2,0,0,1,20.093,13Zm-7.055,3.536a3.541,3.541,0,1,1,3.541-3.541A3.54,3.54,0,0,1,13.038,16.534Z"
        transform="translate(-4.5 -4.5)"
      />
    </svg>
  );
};

export default AbonnementIcon;
