import ArrowBottom from "../../icons/Arrow/ArrowBottom";
import ArrowRight from "../../icons/Arrow/ArrowRight";
import ArrowTop from "../../icons/Arrow/ArrowTop";
import Link from "../Link/Link";
import { MenuOptions } from "./Menu.d";
import "./Menu.scss";
import React, { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";

const _showLinkIfRedirection = (
  children: ReactNode,
  redirect: string | undefined
) => (
  <React.Fragment>
    {redirect ? <Link to={redirect}>{children}</Link> : children}
  </React.Fragment>
);

const Menu = ({ title, menu, redirect }: MenuOptions) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(-1);
  const div1Ref = useRef<any>(null);
  const [div2Position, setDiv2Position] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (div1Ref.current) {
      const div1Rect = div1Ref.current.getBoundingClientRect();
      setDiv2Position({
        top: div1Rect.top - 40,
        left: div1Rect.right,
      });
    }
  }, [showMenu]);

  return (
    <div
      className="menu"
      onMouseOver={() => setShowMenu(true)}
      onMouseLeave={() => {
        setShowMenu(false);
        setShowSubMenu(-1);
      }}
    >
      {_showLinkIfRedirection(
        <div>
          <div>{title}</div>
          {menu && menu.length > 0 && (
            <React.Fragment>
              {showMenu ? <ArrowTop /> : <ArrowBottom />}
            </React.Fragment>
          )}
        </div>,
        redirect
      )}
      {menu?.length && (
        <div
          className="submenu"
          style={{ display: showMenu ? "block" : "none" }}
        >
          <div>
            {menu.map((menuElement, index) => (
              <div
                key={`menu-element-${index}`}
                className="menu-element"
                ref={div1Ref}
                onMouseLeave={() => setShowSubMenu(-1)}
                onMouseOver={() => setShowSubMenu(index)}
              >
                {_showLinkIfRedirection(
                  <React.Fragment>
                    <div>
                      <div className="title-menu">{menuElement.title}</div>
                      {menuElement.submenu &&
                        menuElement.submenu.length > 0 && <ArrowRight />}
                    </div>
                    {menuElement.submenu && (
                      <div
                        className="submenu2"
                        style={{
                          top: div2Position.top,
                          left: div2Position.left,
                          display: showSubMenu === index ? "block" : "none",
                        }}
                        onMouseLeave={() => setShowSubMenu(-1)}
                      >
                        <div>
                          {menuElement.submenu.map((sub, idx) => (
                            <div key={`submenu-element-${idx}`}>
                              {_showLinkIfRedirection(
                                <div>
                                  <div className="title-menu">{sub.title}</div>
                                </div>,
                                sub?.redirect
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </React.Fragment>,
                  menuElement?.redirect
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
