import { useUserSession } from "../context/UserContext";
import { useState, useEffect } from "react";

const checkDateForShowingHeadBand = (date: Date) =>
  date.getDay() === 0 &&
  date.getDate() <= 7 &&
  // date.getDate() >= 8 &&
  // date.getDate() <= 14 &&
  [17, 18].includes(date.getHours());

const useShowDiv = () => {
  const [showDivBellyPremium, setShowDivBellyPremium] = useState(false);
  const { haveBellyPremium } = useUserSession();

  useEffect(() => {
    const checkCondition = () => {
      const now = new Date();
      if (haveBellyPremium) {
        if (checkDateForShowingHeadBand(now)) {
          setShowDivBellyPremium(true);
        } else {
          setShowDivBellyPremium(false);
        }
      } else setShowDivBellyPremium(false);
    };

    checkCondition();

    const intervalId = setInterval(checkCondition, 60000);

    return () => clearInterval(intervalId);
  }, [haveBellyPremium]);

  return showDivBellyPremium;
};

export default useShowDiv;
