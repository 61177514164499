const CalendarSVG = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="31.5" height="36" viewBox="0 0 31.5 36" {...props}>
      <path
        id="Icon_awesome-calendar-week"
        data-name="Icon awesome-calendar-week"
        d="M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0Zm4.5-13.5A1.128,1.128,0,0,1,5.625,18h20.25A1.128,1.128,0,0,1,27,19.125v4.5a1.128,1.128,0,0,1-1.125,1.125H5.625A1.128,1.128,0,0,1,4.5,23.625ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z"
        fill="#e90d2f"
      />
    </svg>
  );
};

export default CalendarSVG;
