import { SVGProps } from "react";

const ProgressionIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      id="icone_progression"
      data-name="icone progression"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_92"
            data-name="Rectangle 92"
            width="20"
            height="20"
          />
        </clipPath>
      </defs>
      <g id="Groupe_36" data-name="Groupe 36" clipPath="url(#clip-path)">
        <path
          id="Tracé_31"
          data-name="Tracé 31"
          d="M149.295,16.165a15.772,15.772,0,0,0-1.779-5.019A14,14,0,0,1,145.775,3.7l.121-1.144a16.8,16.8,0,0,1,.343-1.7A.693.693,0,0,0,145.576,0h-9.628a.693.693,0,0,0-.669.84,16.766,16.766,0,0,1,.439,2.86,13.961,13.961,0,0,1-1.163,6.371,8.051,8.051,0,0,1,5.873,1.365,3.185,3.185,0,0,1,1.165,3.483q-.043.141-.1.277l5.142.139.185.013c0,.045,0,1.58,0,1.58h1.787a.693.693,0,0,0,.682-.763m-8.25-6.349a.366.366,0,0,1-.176.184h-.039a.366.366,0,0,1-.176-.184c-.029-.064.024-.125.039-.184a1.746,1.746,0,0,1,.059-.184c.024-.061.047-.123.078-.184h.039a2.058,2.058,0,0,1,.137.368c.015.059.068.12.039.184"
          transform="translate(-129.299)"
        />
        <path
          id="Tracé_32"
          data-name="Tracé 32"
          d="M70.238,312a7.013,7.013,0,0,0-4.457.29c-.641.348-.66.715.013,1.051a5.229,5.229,0,0,0,1.73.419,8.647,8.647,0,0,0,2.7-.162c.376-.091,1.257-.344,1.307-.77-.028-.429-.923-.726-1.293-.828"
          transform="translate(-62.744 -299.595)"
        />
        <path
          id="Tracé_33"
          data-name="Tracé 33"
          d="M17.177,270.021l-5.466.118-.434.009.143-.434a6.121,6.121,0,0,0,.16-1.325c-.009-2.014-2.165-3.061-3.885-3.448a11.92,11.92,0,0,0-3.114-.262c-1.756.182-4.582.882-4.582,2.882v3.828c0,2.438,3.973,2.941,5.724,2.941H16.542a.724.724,0,0,0,.723-.723Zm-14.5,3.254-.586-.236v-.658a.293.293,0,0,1,.586,0Zm2.1.43-.586-.068v-1.9a.293.293,0,0,1,.586,0Zm2.1.041H6.3v-.787a.293.293,0,0,1,.586,0Zm2.1,0H8.4V271.8a.293.293,0,0,1,.586,0ZM8.5,268.42a6.36,6.36,0,0,1-2.813.535,5.315,5.315,0,0,1-3.216-.863.669.669,0,0,1-.162-.821,2.385,2.385,0,0,1,1.5-.813,8.457,8.457,0,0,1,1.875-.194,9.431,9.431,0,0,1,1.88.175c.569.127,1.583.41,1.618,1.128,0,.406-.368.686-.684.853m2.588,5.326H10.5v-.787a.293.293,0,0,1,.586,0Zm2.1,0H12.6V271.8a.293.293,0,0,1,.586,0Z"
          transform="translate(0 -254.331)"
        />
      </g>
    </svg>
  );
};

export default ProgressionIcon;
