import { setToken } from "../../services/AccessTokenService";
import { FormEvent } from "react";
import { toast } from "react-toastify";

export const submitConnection = (
  event: FormEvent<HTMLFormElement>,
  navigate: Function,
  setLogged: Function
) => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);

  fetch(`${process.env.REACT_APP_API_URL}/login`, {
    method: "POST",
    body: formData,
  })
    .then((res) => res.json())
    .catch(() => toast.error("Une erreur s'est produite."))
    .then(({ access_token, refresh_token, detail }) => {
      if (detail) {
        toast.error(
          "Ce compte n'existe pas ou le mot de passe ne correspond pas."
        );
      } else {
        setToken(access_token, refresh_token);
        setLogged(true);
        navigate("/");
      }
    });
};
