import { SVGProps } from "react";

const TikTokSvg = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="24" height="24" viewBox="0 0 108 108" {...props}>
      <path
        d="M22-5677A53.671,53.671,0,0,1,.98-5681.244a54.055,54.055,0,0,1-9.173-4.979,54.386,54.386,0,0,1-7.992-6.594,54.382,54.382,0,0,1-6.594-7.992,53.987,53.987,0,0,1-4.979-9.172A53.663,53.663,0,0,1-32-5731a53.662,53.662,0,0,1,4.244-21.019,54.006,54.006,0,0,1,4.979-9.172,54.36,54.36,0,0,1,6.594-7.992,54.406,54.406,0,0,1,7.992-6.595,54.022,54.022,0,0,1,9.173-4.979A53.66,53.66,0,0,1,22-5785a53.661,53.661,0,0,1,21.019,4.244,54.023,54.023,0,0,1,9.173,4.979,54.408,54.408,0,0,1,7.992,6.595,54.366,54.366,0,0,1,6.594,7.992,54.006,54.006,0,0,1,4.979,9.172A53.661,53.661,0,0,1,76-5731a53.664,53.664,0,0,1-4.243,21.02,53.988,53.988,0,0,1-4.979,9.172,54.389,54.389,0,0,1-6.594,7.992,54.383,54.383,0,0,1-7.992,6.594,54.057,54.057,0,0,1-9.173,4.979A53.671,53.671,0,0,1,22-5677Zm-7.571-62.513a20.671,20.671,0,0,0-13.35,5.019,20.445,20.445,0,0,0-7.049,13.551c-.048,1.159-.039,2.414.028,4.071a20.579,20.579,0,0,0,9.971,15.6,20,20,0,0,0,10.135,2.842c.333,0,.674-.01,1.012-.028a19.865,19.865,0,0,0,9.118-2.458,20.258,20.258,0,0,0,7.028-6.311,21,21,0,0,0,3.688-10.764c.051-4.963.049-10.021.047-14.912,0-2.946,0-5.992.008-8.993a33.927,33.927,0,0,0,4.425,2.541,29.023,29.023,0,0,0,11.474,2.649v-11.009a24.159,24.159,0,0,1-6.222-1.482,15.764,15.764,0,0,1-5.361-3.409,14.9,14.9,0,0,1-3.456-5.234A19.169,19.169,0,0,1,34.6-5764c-1.379.011-2.741.013-4.25.015h-.024c-2.1,0-4.265.007-6.408.04-.051,6.855-.049,13.828-.047,20.57v.017c0,4.059,0,8.256-.008,12.389-.007,1.277,0,2.574,0,3.828.007,3.541.013,7.2-.188,10.815a5.9,5.9,0,0,1-1.028,2.736l-.093.16a9.207,9.207,0,0,1-7.567,4.4c-.228.02-.458.03-.686.03a9.357,9.357,0,0,1-8.876-7.871c0-.232-.012-.476-.019-.69a8.857,8.857,0,0,1,.4-3.708,9.462,9.462,0,0,1,3.715-4.781,8.911,8.911,0,0,1,5.093-1.507,10.248,10.248,0,0,1,3.158.5c0-1.732.031-3.491.06-5.193.04-2.273.08-4.624.049-6.937A18.6,18.6,0,0,0,14.429-5739.513Z"
        transform="translate(32 5785)"
      />
    </svg>
  );
};
export default TikTokSvg;
