import Header from "../components/Header/Header";
import Alimentation from "../pages/Alimentation/Alimentation";
import ArticlesLives from "../pages/ArticlesLives/ArticlesLives";
import BellyPremium from "../pages/BellyPremium/BellyPremium";
import Challenges from "../pages/Challenges/Challenges";
import Course from "../pages/Course/Course";
import CourseChallenge from "../pages/CourseChallenge/CourseChallenge";
import CourseV2 from "../pages/CourseV2/CourseV2";
import Homepage from "../pages/Home/Home";
import Lesson from "../pages/Lesson/Lesson";
import Login from "../pages/Login/Login";
import Training from "../pages/Training/Training";
import { ProtectedRoute } from "./ProtectedRoute";
import { Navigate, useRoutes } from "react-router-dom";

const APP_ROUTES = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Header />
        <Homepage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/courses/:course_id",
    element: (
      <ProtectedRoute>
        <Header />
        <Course />
      </ProtectedRoute>
    ),
  },
  {
    path: "/articles/:course_id",
    element: (
      <ProtectedRoute>
        <Header />
        <ArticlesLives type="articles" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/lives/:course_id",
    element: (
      <ProtectedRoute>
        <Header />
        <ArticlesLives type="lives" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/courses-v2/:course_id",
    element: (
      <ProtectedRoute>
        <Header />
        <CourseV2 />
      </ProtectedRoute>
    ),
  },
  {
    path: "/courses-challenge/:course_id",
    element: (
      <ProtectedRoute>
        <Header />
        <CourseChallenge />
      </ProtectedRoute>
    ),
  },
  {
    path: "/lessons/:lesson_id",
    element: (
      <ProtectedRoute>
        <Header />
        <Lesson />
      </ProtectedRoute>
    ),
  },
  {
    path: "/training",
    element: (
      <ProtectedRoute>
        <Header />
        <Training />
      </ProtectedRoute>
    ),
  },
  {
    path: "/alimentation",
    element: (
      <ProtectedRoute>
        <Header />
        <Alimentation />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/progression",
  //   element: (
  //     <ProtectedRoute>
  //       <Homepage />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/challenges",
    element: (
      <ProtectedRoute>
        <Header />
        <Challenges />
      </ProtectedRoute>
    ),
  },
  {
    path: "/cgu",
    element: (
      <ProtectedRoute>
        <Homepage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/commands",
    element: (
      <ProtectedRoute>
        <Homepage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/paiements",
    element: (
      <ProtectedRoute>
        <Homepage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/subscription",
    element: (
      <ProtectedRoute>
        <Homepage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/belly-premium",
    element: (
      <ProtectedRoute>
        <Header />
        <BellyPremium />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

const RoutesComponent = () => {
  const routes = useRoutes(APP_ROUTES);
  return routes;
};

export default RoutesComponent;
