import { UserProvider } from "../context/UserContext";
import RoutesComponent from "./AppRoutes";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <UserProvider>
      <BrowserRouter>
        <RoutesComponent />
      </BrowserRouter>
    </UserProvider>
  );
};

export default App;
